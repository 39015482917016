import { AppTable, AppTableColumn, ModalButton } from 'src/app/shared/_models/models';
import {
  AlertsConfig,
  AlertsTable,
  AlertsTableRow,
  AlertTargetDetails,
  EditAlertsTable,
  INTERNAL_ALERTS_SENTINEL_GROUP
} from '../types/config.types';
import { of } from 'rxjs';

export const internalAlertsTableColumns: AppTableColumn[] = [
  {
    id: 'notificationType',
    prop: 'name',
    displayName: 'Notification Type',
    type: 'text',
    valueFormatter: (row: AlertsTableRow) => row.description,
    colWidth: 'max-content'
  },
  {
    id: 'emailAlert',
    prop: 'alertTargets',
    displayName: 'Publisher Name',
    type: 'moreTag',
    actionId: 'alertToggleAction',
    moreTag: {
      displayNameProp: 'target',
      popoverTitle: 'More publishers',
      noDataMessage: 'No publishers selected'
    },
    colWidth: 'max-content'
  },
  {
    id: 'editAlert', prop: 'actions', displayName: '', type: 'actions', actionId: INTERNAL_ALERTS_SENTINEL_GROUP,
    actions: [
      { id: INTERNAL_ALERTS_SENTINEL_GROUP, name: 'edit', auth: 'canUpdate', displayName: 'Edit' }
    ],
    colWidth: 'max-content'
  },
];

const alertsTable = new AppTable('publisherDealsInternalAlertsTable', 1, 10, 'description', 'asc', false, false, 'no-border');

const authPermissions = {
  canRead: true,
  canCreate: true,
  canUpdate: true,
  canDelete: true,
};

export const publisherDealsInternalAlertsTable: AlertsTable = {
  group: INTERNAL_ALERTS_SENTINEL_GROUP,
  title: 'Publisher Deal Alerts',
  hasFooter: false,
  table: alertsTable,
  tableColumns: internalAlertsTableColumns,
  authUser: authPermissions,
  isLoading: true,
  tableRows$: of([]),
};

export const editAlertsTableColumns: AppTableColumn[] = [
  { id: 'publisherId', prop: 'alertTarget.targetId', displayName: 'ID', type: 'text' },
  { id: 'publisherName', prop: 'alertTarget.targetName', displayName: 'Publisher', type: 'text' },
  { id: 'emailAlert', prop: 'type', displayName: 'Email Me', type: 'toggle-alert', actionId: 'alertToggleAction' },
  {
    id: 'alertActions', prop: 'actions', displayName: '', type: 'actions',
    actions: [
      {
        id: 'alertDeleteAction',
        name: 'delete',
        auth: 'canDelete',
        displayName: 'Delete',
        desc: 'Are you sure you wish to delete this publisher\'s alert?'
      }
    ]
  }
];

export const editPublisherDealsInternalAlertsTable: EditAlertsTable = {
  table: alertsTable,
  tableColumns: editAlertsTableColumns,
  authUser: authPermissions,
  isLoading: true,
  alertsTableData: [],
};



export const addAlertToPublisherModalButtons: ModalButton[] = [
  { type: 'CANCEL', text: 'Cancel' },
  { type: 'APPROVE', text: 'Save' }
];

export const publisherDealsInternalAlertsTargetsFields: AlertTargetDetails = {
  targetId: 'publisherId',
  targetName: 'publisherName',
};



export const publisherDealsInternalAlertsConfig: AlertsConfig = {
  alertsTable: publisherDealsInternalAlertsTable,
  editAlertsTable: editPublisherDealsInternalAlertsTable,
  addAlertModalButton: addAlertToPublisherModalButtons,
  alertsTargetsFields: publisherDealsInternalAlertsTargetsFields,
  subscriptionTargetField: 'publishers',
  addAlertTargetButtonText: 'publisher'
};
