import { CtrlUserAuthorities } from '@/app/shared/_models/models';
import {
  AlertsConfig,
  EXCHANGE_DEALS_INTERNAL_ALERTS_SENTINEL_GROUP,
  EXTERNAL_ALERTS_SENTINEL_GROUP, INTERNAL_ALERTS_SENTINEL_GROUP,
  SentinelRuleGroup
} from '../types';
import { publisherDealsInternalAlertsConfig } from './deals-internal.config';
import { publisherDealsExternalAlertsConfig } from './deals-external.config';
import { exchangeDealsInternalAlertsConfig } from './exchange-deals-internal.config';


export class AlertsConfigMapper {

  public static getConfigs(user: CtrlUserAuthorities): AlertsConfig[] {
    return user.type === 'INTERNAL' ?
      this.getInternalUserConfig(user.permissions) :
      this.getExternalUserConfig(user.permissions);
  }

  public static getConfigByRuleGroup(ruleGroup: SentinelRuleGroup): AlertsConfig {
    switch (ruleGroup) {
      case INTERNAL_ALERTS_SENTINEL_GROUP:
        return publisherDealsInternalAlertsConfig;
      case EXTERNAL_ALERTS_SENTINEL_GROUP:
        return publisherDealsExternalAlertsConfig;
      case EXCHANGE_DEALS_INTERNAL_ALERTS_SENTINEL_GROUP:
        return exchangeDealsInternalAlertsConfig;
      default:
        throw new Error(`Unsupported alert config: ${ruleGroup}`);
    }
  }

  private static getInternalUserConfig(permissions: string[]): AlertsConfig[] {
    const configs: AlertsConfig[] = [];

    if (permissions.includes('supply.pubops.read')) {
      configs.push(publisherDealsInternalAlertsConfig);
    }

    if (permissions.includes('exchange-deals-reporting.read') || permissions.includes('deal-marketplace.read')) {
      configs.push(exchangeDealsInternalAlertsConfig);
    }

    return configs;
  }

  private static getExternalUserConfig(permissions: string[]): AlertsConfig[] {
    const configs: AlertsConfig[] = [];

    if (permissions.includes('publisher.deal.show')) {
      configs.push(publisherDealsExternalAlertsConfig);
    }

    return configs;
  }
}
