import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { CtrlUser, ResponseFromServer } from '../../../../_models/models';
import { SharedService } from 'src/app/shared/_services/shared.service';
import { EMPTY, Observable, Subject } from 'rxjs';
import { AlertsConfig, AlertTargetDetails } from 'src/app/shared/_services/alerts/types/config.types';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import { filter } from 'rxjs/operators';
import { debounceTime } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { takeUntil } from 'rxjs/operators';
import { IAlertTargets } from '@/app/shared/_services/alerts/targets';
import { AddAlertTargetForm } from '@/app/shared/_services/alerts/types';

@Component({
  selector: 'app-add-target-to-alert',
  templateUrl: './add-target-to-alert.component.html',
  styleUrls: ['./add-target-to-alert.component.less']
})
export class AddTargetToAlertComponent implements OnInit, OnDestroy {
  @Input()
    addAlertTargetForm: FormGroup<AddAlertTargetForm>;
  @Input()
    user: CtrlUser;
  @Input()
    alertTargetsHandler: IAlertTargets;
  @Input()
    alertConfig: AlertsConfig;
  @Output()
    informOnSubmit: EventEmitter<boolean> = new EventEmitter<boolean>();


  alertTargetSearch$: Subject<string> = new Subject();

  isLoading = false;
  alertTargets: AlertTargetDetails[] = [];
  selectedTarget: AlertTargetDetails;


  private unsubscribe$ = new Subject<void>();
  private searchListenerInitialized = false;

  constructor(
    private readonly sharedService: SharedService
  ) { }

  ngOnInit(): void {
    if (!this.searchListenerInitialized) {
      this.initSelectListener();
      this.initAlertTargets();
      this.initSearchListener();
      this.searchListenerInitialized = true;
    }

  }

  onAlertTargetSelect(selectedAlertTarget): void {
    this.selectedTarget = this.alertTargets?.find(target => target.targetId === selectedAlertTarget);
  }

  initAlertTargets() {
    this.alertTargetsHandler.fetchTargetsData()
      .pipe(
        takeUntil(this.unsubscribe$),
        catchError(() => this.sharedService.handleErrors('Failed to load alert targets')))
      .subscribe(res => {
        this.alertTargets = res.data;
      });
  }

  initSearchListener(): void {
    this.alertTargetSearch$.pipe(
      debounceTime(700),
      distinctUntilChanged(),
      filter(val => val === '' || val.length >= 2),
      switchMap(val => this.onSearchAlertTargets(val)),
      catchError(() => {
        this.isLoading = false;
        return EMPTY;
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe((res: ResponseFromServer<AlertTargetDetails[]>) => {
      this.alertTargets = res.data;
      this.isLoading = false;
    });
  }

  onSearchAlertTargets(searchValue: string): Observable<ResponseFromServer<AlertTargetDetails[]>> {
    this.isLoading = true;
    return this.alertTargetsHandler.searchTargets(searchValue);
  }

  onSubmit(): void {
    this.informOnSubmit.emit(this.addAlertTargetForm.valid);
  }

  onSearch(value: string): void {
    this.isLoading = true;
    this.alertTargetSearch$.next(value);
  }

  initSelectListener(): void {
    this.addAlertTargetForm.controls.targetId.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        if (!value) {
          this.alertTargetSearch$.next('');
        }
      });
  }

  getOptionLabel(option: AlertTargetDetails): string {
    if (option.targetName && option.targetName.length > 0) {
      return option.targetName;
    }
    return option.targetDescription;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
