
import { Injectable } from '@angular/core';
import { DealsInternalAlertTargets } from './deals-internal-alert-targets';
import { ExchangeDealsInternalAlertTargets } from './exchange-deals-internal-alert-target';
import { IAlertTargets } from './ialert-targets';
import { AlertsConfig, EXCHANGE_DEALS_INTERNAL_ALERTS_SENTINEL_GROUP, INTERNAL_ALERTS_SENTINEL_GROUP } from '../types/config.types';
import { SupplyPublisherService } from '@/app/features/supply/publishers/_services/publisher.service';
import { ExchangeDealsService } from '@/app/features/exchange-deals/_services/exchange-deals.service';

@Injectable({
  providedIn: 'root'
})
export class AlertTargetsFactory {
  constructor(
    private readonly publishersService: SupplyPublisherService,
    private readonly exchnageDealsService: ExchangeDealsService
  ) {}

  createAlertTargets(config: AlertsConfig): IAlertTargets {
    switch (config.alertsTable.group) {
      case INTERNAL_ALERTS_SENTINEL_GROUP:
        return new DealsInternalAlertTargets(this.publishersService);
      case EXCHANGE_DEALS_INTERNAL_ALERTS_SENTINEL_GROUP:
        return new ExchangeDealsInternalAlertTargets(this.exchnageDealsService);
      default:
        throw new Error(`Unknown alert targets type: ${config.alertsTable.group}`);
    }
  }
}
