import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AlertTargetDetails } from '../types/config.types';
import { ExchangeDealsService } from '@/app/features/exchange-deals/_services/exchange-deals.service';
import { ExchangeDeal, ResponseFromServer } from '@/app/shared/_models/models';
import { SentinelSubscriptionResponse } from '@/app/shared/_models/models';
import { RequestQueryBuilder } from '@libs/crud-request-nestjs';
import { CreateQueryParams } from '@libs/crud-request-nestjs';
import { IAlertTargets } from './ialert-targets';

@Injectable()
export class ExchangeDealsInternalAlertTargets implements IAlertTargets {

  constructor(
    private readonly exchangeDealsService: ExchangeDealsService,
  ) {}

  fetchTargetsData(subscriptions: SentinelSubscriptionResponse): Observable<ResponseFromServer<AlertTargetDetails[]>> {
    const query = subscriptions ?
      this.buildTargetsQuery(subscriptions) :
      this.createExchangeDealsQueryByDealIds();

    return this.exchangeDealsService.getExchangeDeals(query)
      .pipe(
        filter(response => !!response?.data),
        map((response: ResponseFromServer<ExchangeDeal[]>) => ({
          data: this.exchangeDealsToAlertTargetDetails(response)
        }))
      );
  }

  searchTargets(searchValue?: string): Observable<ResponseFromServer<AlertTargetDetails[]>> {

    const query = this.generateQueryWithSearchValue(searchValue);

    return this.exchangeDealsService.getExchangeDeals(query)
      .pipe(
        filter(response => !!response?.data),
        map((response: ResponseFromServer<ExchangeDeal[]>) => ({
          data: this.exchangeDealsToAlertTargetDetails(response)
        }))
      );
  }

  private buildTargetsQuery(subscriptions: SentinelSubscriptionResponse | SentinelSubscriptionResponse[]) {
    let exchangeDealsIds: (string | number)[];
    if (Array.isArray(subscriptions)) {
      exchangeDealsIds = subscriptions.flatMap(sub => sub.templateValue.values?.exchangeDeals);
    } else {
      exchangeDealsIds = subscriptions.templateValue.values?.exchangeDeals;
    }

    return this.createExchangeDealsQueryByDealIds({ externalDealId: { $in: exchangeDealsIds } });
  }

  private createExchangeDealsQueryByDealIds(queryParams?: any): string {

    const exchangeDealsQueryParams: CreateQueryParams = {
      search: {
        status: { $in: ['ACTIVE'] },
        ...queryParams
      },
      page: 1,
      limit: 30,
      fields: ['dealId', 'name', 'description', 'externalDealId'],
      sort: [
        {
          field: 'description',
          order: 'ASC'
        },
        {
          field: 'name',
          order: 'ASC'
        },
        {
          field: 'externalDealId',
          order: 'ASC'
        }
      ]
    };

    return RequestQueryBuilder.create(exchangeDealsQueryParams).query();
  }

  private exchangeDealsToAlertTargetDetails(exchangeDeals: ResponseFromServer<ExchangeDeal[]>): AlertTargetDetails[] {
    return exchangeDeals.data.map((exchangeDeal: ExchangeDeal) => ({
      targetId: exchangeDeal.externalDealId,
      targetName: exchangeDeal.name,
      targetDescription: exchangeDeal.description,
    }));
  }

  private generateQueryWithSearchValue(searchValue: string): string {
    const queryParams = {
      status: { $in: ['ACTIVE'] }
    };

    if (searchValue && searchValue.length > 0) {
      queryParams['$or'] = [
        { externalDealId: { $contL: '%' + searchValue + '%' } },
        { name: { $contL: '%' + searchValue + '%' } },
        { description: { $contL: '%' + searchValue + '%' } }
      ];
    }

    return this.createExchangeDealsQueryByDealIds(queryParams);
  }

}
