<ng-container>
  <form nzLayout="vertical">
    <app-table
      *ngFor="let config of alertsConfigs"
      [tableTitle]="config.alertsTable.title"
      [hasFooter]="config.alertsTable.hasFooter"
      [table]="config.alertsTable.table"
      [tableColumns]="config.alertsTable.tableColumns"
      [tableData]="config.alertsTable.tableRows$ | async"
      [authPermissions]="config.alertsTable.authUser"
      [isTableLoading]="config.alertsTable.isLoading"
      (alertRowAction)="onAlertRowAction($event)">
    </app-table>
  </form>
</ng-container>