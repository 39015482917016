<div class="card-wrapper">
    <nz-card>
      <div class="sectionTitle justify-content-space-between">
        <div class="display-flex capitalize-text align-items-center">
          <fa-icon icon="bell"></fa-icon>
          <nz-spin [nzSpinning]="alertConfig.editAlertsTable.isLoading">
            {{currentAlert.name}}
          </nz-spin>
        </div>
        <button nz-button nzSize="large" nzType="primary" class="primary-button" id="add-subject-btn" (click)="toggleModalView()">
          Add {{alertConfig.addAlertTargetButtonText | titlecase}}
        </button>
      </div>
      <app-table [tableData]="alertConfig.editAlertsTable.alertsTableData"
                 [tableColumns]="alertConfig.editAlertsTable.tableColumns"
                 [table]="alertConfig.editAlertsTable.table"
                 [authPermissions]="alertConfig.editAlertsTable.authUser"
                 (alertRowAction)="onAlertRowAction($event)"
                 [isTableLoading]="alertConfig.editAlertsTable.isLoading"
      >
      </app-table>
      <app-modal
        [isModalVisible]="isModalVisible"
        [modalButtons]="alertConfig.addAlertModalButton"
        [modalWidth]="400"
        (modalEvent)="onModalEvent($event)"
      >
        <app-add-target-to-alert
          [addAlertTargetForm]="addAlertTargetForm"
          [user]="user"
          [alertConfig]="alertConfig"
          [alertTargetsHandler]="alertTargetsHandler"
          (informOnSubmit)="informModalSubmit($event)"
        ></app-add-target-to-alert>
      </app-modal>
    </nz-card>
  </div>
  
  
  