import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewChildren,
} from '@angular/core';

import * as _ from 'lodash';
import { isEqual } from 'lodash';
import {AppSelections, AppTable, AppTableAction, AppTableColumn, AuthPermissions, CustomComponentConfig} from '../../_models/models';

import { animate, state, style, transition, trigger, } from '@angular/animations';
import { Common } from '../../_common/common.helper';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomComponentHostDirective } from '../../_directives/custom-component-host.directive';
import { EXCHANGE_DEALS_INTERNAL_ALERTS_SENTINEL_GROUP, EXTERNAL_ALERTS_SENTINEL_GROUP, INTERNAL_ALERTS_SENTINEL_GROUP } from '../../_services/alerts/types/config.types';

@Component({
  selector: 'app-table',
  animations: [
    trigger('hideColumn', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(300px)', offset: 1.0 }),
        animate('0.5s ease-in')
      ]),
      transition(':leave', [
        style({opacity: .5, transform: 'translateY(-75%)',  offset: 0.3}),
        animate('0.5s ease-out')
      ]),
    ]),
    trigger('freezeColumn', [
      state('freeze', style({
        color: '#FDD032'
      })),
      state('unfreeze', style({
        color: 'inherit'
      })),
      // transition('freeze => unfreeze', [
      //   animate('1s')
      // ]),
      // transition('unfreeze => freeze', [
      //   animate('0.5s')
      // ]),
    ]),
  ],
  templateUrl: './app-table.component.html',
  styleUrls: ['./app-table.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppTableComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('appTable') appTableEl;
  @ViewChildren(CustomComponentHostDirective) customComponentHosts!: CustomComponentHostDirective[];

  @Input() tableTitle: string;
  @Input() tableType: string;
  // @Input() tableColumns: Array<any>;
  @Input() innerTableColumns: Array<any>;
  @Input() table: AppTable;
  @Input() selections: AppSelections;
  @Input() authPermissions: AuthPermissions;
  @Input() scrollConfig: any = {x: 'inherit'};
  @Input() isExternal = false;
  @Input() idProp: string;
  @Input() hasAccess: string;
  @Input() exportCSV = false;
  @Input() hasFooter = false;

  private _tableData: any;
  @Input()
  set tableData(tableData) {
    if (!isEqual(this._tableData, tableData)) {
      this._tableData = tableData;
      if (this._tableData) {
        this.totalClientSideRows = this._tableData.length;
        setTimeout(() => {
          this.calcStickyWidthLeft();
          this._tableData?.forEach((row, idx) => {
            if (row.isExpanded) {
              this.loadCustomComponent(row, idx, 'expandRowComponent');
            }
            if (row.trComponent) {
              this.loadCustomComponent(row, idx, 'trComponent');
            }
          });
        }, 100);
      }
    }
  }
  get tableData() {
    return this._tableData;
  }

  private _tableColumns: Array<any>;
  @Input()
  set tableColumns(tableColumns) {
    this._tableColumns = tableColumns;
    if (this._tableColumns) {
      setTimeout(() => {
        this.calcStickyWidthLeft();
      }, 100);
    }
  }
  get tableColumns(): AppTableColumn[] {
    return this._tableColumns;
  }

  @Input() tableTotalsData: any;
  @Input() totalRows: number;
  @Input() isTableLoading: boolean;
  @Input() isDownloading: boolean;
  @Input() isPaginated = true;
  @Input() hasSizeChanger = true;

  @Output() trafficSourcesAction: EventEmitter<any> = new EventEmitter();
  @Output() adServerTechAction: EventEmitter<any> = new EventEmitter();
  @Output() termsOfServiceAction: EventEmitter<any> = new EventEmitter();
  @Output() emailTemplateAction: EventEmitter<any> = new EventEmitter();
  @Output() inventoryPackagesAction: EventEmitter<any> = new EventEmitter();
  @Output() kvpValuesAction: EventEmitter<any> = new EventEmitter();
  @Output() inventoryPackagesGeoAction: EventEmitter<any> = new EventEmitter();
  @Output() dealsAction: EventEmitter<any> = new EventEmitter();
  @Output() exchangeDealsAction: EventEmitter<any> = new EventEmitter();
  @Output() exchangeForecastsAction: EventEmitter<any> = new EventEmitter();
  @Output() exchangePartnersAction: EventEmitter<any> = new EventEmitter();
  @Output() publishersAction: EventEmitter<any> = new EventEmitter();
  @Output() placementsAction: EventEmitter<any> = new EventEmitter();
  @Output() domainBundlesAction: EventEmitter<any> = new EventEmitter();
  @Output() domainBundlesChangesAction: EventEmitter<any> = new EventEmitter();
  @Output() badvAction: EventEmitter<{row: any; id: number | string; action: string}> = new EventEmitter();
  @Output() rolesAction: EventEmitter<any> = new EventEmitter();
  @Output() usersAction: EventEmitter<any> = new EventEmitter();
  @Output() billingHistoryAction: EventEmitter<any> = new EventEmitter();
  @Output() alertRowAction: EventEmitter<{row: any; action: string}> = new EventEmitter();

  @Output() tablePageChanged: EventEmitter<any> = new EventEmitter();
  @Output() tableSortChanged: EventEmitter<any> = new EventEmitter();
  @Output() selectionsChanged: EventEmitter<any> = new EventEmitter();
  @Output() pageIndexChanged: EventEmitter<any> = new EventEmitter();
  @Output() exportClicked: EventEmitter<any> = new EventEmitter();
  sortDirection: any;

  confirmId: number | string;
  columnsProps: string[];
  totalClientSideRows: number;
  frozenColumnsLeftValues = [];

  @HostListener('document:click', ['$event.target'])
  onClick() {
    this.confirmId = null;
  }

  constructor(
    private cdr: ChangeDetectorRef,
    public common: Common,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    if (!this._tableData) {
      return;
    }

    this.columnsProps = _.map(this.tableColumns, 'prop');
  }

  ngAfterViewInit() {
    if (!this._tableData || this._tableData.length === 0) {
      return;
    }
  }

  hasPermissions() {
    if (this.authPermissions && this.authPermissions.hasOwnProperty('showActions')) {
      return this.authPermissions.showActions && Object.values(this.authPermissions).indexOf(true) > -1;
    } else {
      return this.authPermissions && Object.values(this.authPermissions).indexOf(true) > -1;
    }
  }

  // onTablePageChange() {
  //   if (this.tableType !== 'clientSide') {
  //     this.tablePageChanged.emit(this.table);
  //   }
  // }

  onPageIndexChanged(event) {
    this.table.pageIndex = event;
    this.tablePageChanged.emit({...this.table});
  }

  onPageSizeChanged(event) {
    this.table.pageSize = event;
    this.table.pageIndex = 1;
    this.tablePageChanged.emit({...this.table});
  }


  onTableSortChange(sortEvent: { key: string; value: string }): void {
    // prevent null sort event
    //  if (!sortEvent.value) {
    //    if (this.sortDirection === 'ascend') {
    //      this.sortDirection = 'descend';
    //    } else {
    //      this.sortDirection = 'ascend';
    //    }
    //  } else {
    //    this.sortDirection = sortEvent.value;
    //  }
    if (this.tableType === 'clientSide') {
      if (sortEvent.value) {
        this.sortDirection = sortEvent.value === 'ascend' ? 'asc' : 'desc';
        this._tableData = _.orderBy(this._tableData, [val => {
          const sortVal = val[sortEvent.key];
          if (typeof sortVal === 'string' || sortVal instanceof String) {
            return sortVal.toLowerCase();
          } else {
            return sortVal;
          }
        }], [this.sortDirection]);
      }
    } else {
      const _table = {...this.table};
      _table.sortBy = sortEvent.key;
      _table.sortDirection = sortEvent.value === 'ascend' ? 'asc' : 'desc';
      this.tableSortChanged.emit(_table);
    }
  }

  onItemsSelectionsChange(changedRow = null, changeAction = null) {
    this.selectionsChanged.emit({selections: this.selections, changedRow: changedRow, changeAction: changeAction});
  }

  selectAll() {
    // tri-state selections model
    if (!this.selections.isSelectAll) {
      this.selections.excludedItems = [];
      this.selections.selectedItems = [];
      this.selections.isSelectAll = !this.selections.isSelectAll;
    } else {
      if (this.selections.excludedItems.length > 0) {
        this.selections.excludedItems = [];
        this.selections.selectedItems = [];
        this.selections.isSelectAll = true;
      } else {
        this.selections.excludedItems = [];
        this.selections.selectedItems = [];
        this.selections.isSelectAll = false;
      }
    }
    this.onItemsSelectionsChange();
  }

  isRowSelected(row) {
    if (this.selections.isSelectAll) {
      return !this.isRowExcluded(row);
    }
    return _.findIndex(this.selections.selectedItems, {id: row.id}) > -1;
  }

  isRowExcluded(row) {
    return _.findIndex(this.selections.excludedItems, {id: row.id}) > -1;
  }

  updateExcludedItems(row) {
    if (this.isRowExcluded(row)) {
      _.remove(this.selections.excludedItems, {id: row.id});
    } else {
      this.selections.excludedItems.push(row);
    }
  }

  updateSelectedObjects(row) {
    if (this.selections.isSelectAll) {
      this.updateExcludedItems(row);
    } else {
      const isSelected = this.isRowSelected(row);
      if (isSelected) {
        _.remove(this.selections.selectedItems, {id: row.id});
      } else {
        this.selections.selectedItems.push(row);
      }
    }

    // check if user unselected one by one all rows in all pages
    if (this.selections.selectedItems.length === 0 && this.selections.excludedItems.length === this.totalRows) {
      this.selections.isSelectAll = false;
      this.selections.excludedItems = [];
    }
    // check if user selected one by one all rows in all pages
    if (this.selections.selectedItems.length === this.totalRows) {
      this.selections.isSelectAll = true;
      this.selections.selectedItems = [];
    }
    this.onItemsSelectionsChange(row);
  }

  onShowConfirmDialog(rowId: number | string): void {
    this.confirmId = rowId;
  }

  onRowAction(row: any, id: number | string, rowIndex: number, actionId: string, data: any = null) {
    const updateRow = _.cloneDeep(row);
    switch (actionId) {
      case 'trafficSourcesView':
      case 'trafficSourcesEdit':
      case 'trafficSourcesDelete':
        this.trafficSourcesAction.emit({row: updateRow, id: id, action: actionId});
        break;
      case 'adServerTechEdit':
      case 'adServerTechDelete':
        this.adServerTechAction.emit({row: updateRow, id: id, action: actionId});
        break;
      case 'termsOfServiceView':
        this.termsOfServiceAction.emit({row: updateRow, id: id, action: actionId});
        break;
      case 'emailTemplateView':
      case 'emailTemplateEdit':
        this.emailTemplateAction.emit({row: updateRow, id: id, action: actionId});
        break;
      case 'inventoryPackagesEdit':
      case 'inventoryPackagesDelete':
      case 'inventoryPackageNameId':
      case 'inventoryPackageSelectForDeal':
      case 'inventoryPackageCopyName':
      case 'inventoryPackagesRunForecast':
        this.inventoryPackagesAction.emit({row: updateRow, id: id, action: actionId});
        break;
      case 'kvpValueDelete':
        this.kvpValuesAction.emit({row: updateRow, id: id, action: actionId});
        break;
      case 'dealEdit':
      case 'dealDuplicate':
      case 'dealCopyId':
      case 'dealStatusToggle':
        this.dealsAction.emit({row: updateRow, id: id, action: actionId});
        break;
      case 'exchangeDealEdit':
      case 'exchangeDealDuplicate':
        this.exchangeDealsAction.emit({row: updateRow, id: id, action: actionId});
        break;
      case 'exchangeForecastsActionDelete':
        this.exchangeForecastsAction.emit({row: updateRow, id: id, action: actionId});
        break;
      case 'exchangePartnersActionDelete':
      case 'exchangePartnersActionUpdateStatus':
        this.exchangePartnersAction.emit({row: updateRow, id: id, action: actionId});
        break;
      case 'publisherStatusToggle':
      case 'publisherEdit':
      case 'publisherDelete':
      case 'publisherView':
        this.publishersAction.emit({row: updateRow, id: id, action: actionId});
        break;
      case 'placementStatusToggle':
      case 'placementEdit':
      case 'placementDuplicate':
      case 'placementView':
        this.placementsAction.emit({row: updateRow, id: id, action: actionId});
        break;
      case 'domainBundleDelete':
      case 'domainBundlesIncludeSubDomains':
        this.domainBundlesAction.emit({row: updateRow, id: id, action: actionId});
        break;
      case 'cancelAction':
        this.domainBundlesChangesAction.emit({row: updateRow, id: id, action: actionId});
        break;
      case 'badvDelete':
        this.badvAction.emit({row: updateRow, id: id, action: actionId});
        break;
      case 'usersView':
      case 'usersEdit':
      case 'usersDelete':
        this.usersAction.emit({row: updateRow, id: id, action: actionId});
        break;
      case 'roleEdit':
      case 'roleDelete':
        this.rolesAction.emit({row: updateRow, id: id, action: actionId});
        break;
      case 'billPaymentDetails':
        this.billingHistoryAction.emit({row: updateRow, id: id, action: actionId});
        break;
      case 'alertToggleAction':
      case INTERNAL_ALERTS_SENTINEL_GROUP:
      case EXCHANGE_DEALS_INTERNAL_ALERTS_SENTINEL_GROUP:
      case EXTERNAL_ALERTS_SENTINEL_GROUP:
      case 'alertDeleteAction':
        this.alertRowAction.emit({row: updateRow, action: actionId});
        break;
      default:
    }
  }

  onExportClicked($event) {
    this.exportClicked.emit($event);
  }

  lodashGet(obj, path, columnId = '') {
    let val = _.get(obj, path);
    if (columnId === 'dealsDefaultBidfloor' && val) {
      val = '$' + val;
    }
    return val;
  }

  calcStickyWidthLeft() {
    this.frozenColumnsLeftValues = [];
    let left = 0;
    this.tableColumns.forEach((col, idx) => {
      if (col.isFrozenCol) {
        this.frozenColumnsLeftValues[idx] = left;
        const width = _.get(this.appTableEl, `listOfNzThComponent._results[${idx}].cdr._view.parent.nodes[0].renderElement.clientWidth`);
        left += width;
      }
    });
    if (this.cdr && !this.cdr['destroyed']) {
      this.cdr.detectChanges();
    }
  }

  getStickyWidthLeft(idx: number) {
    if (this.frozenColumnsLeftValues[idx] >= 0) {
      return `${this.frozenColumnsLeftValues[idx]}px`;
    }
  }

  getHrefLink(link: string, id: number | string) {
    return link.replace('{{id}}', id.toString());
  }

  getDisplayNameFormatterValue(col) {
    return <AppTableColumn>col.displayNameFormatter(col);
  }

  routeIf(route: string[], openTab: boolean = false): void {
    if (openTab) {
      const urlTree = this.router.createUrlTree(route, { relativeTo: this.route });
      const url = this.router.serializeUrl(urlTree);
      window.open(url, '_blank');
    } else {
      this.router.navigate(route, {relativeTo: this.route});
    }
  }

  loadCustomComponent(row, rowIndex, customComponentRowKey: string): void {
    const componentConfig: CustomComponentConfig = row[customComponentRowKey];
    const host =
      this.customComponentHosts.filter((node, idx) => idx === rowIndex)[0];
    if (componentConfig?.component) {
      host.componentConfig = componentConfig;
      if (componentConfig.data) {
        host.componentConfig.data = componentConfig.data;
      }
    }
  }

  getVisibleActions(row: any, actions: AppTableAction[]): AppTableAction[] {
    if (!actions) {
      return [];
    }
    
    return actions.filter(action => {
      return !action.condition || action.condition(row);
    });
  }

  ngOnDestroy(): void {
    this.cdr.detach();
  }
}
