import { AppTable, AppTableColumn } from 'src/app/shared/_models/models';
import {
  AlertsConfig,
  AlertsTable,
  AlertsTableRow,
  EXTERNAL_ALERTS_SENTINEL_GROUP
} from '../types';
import { of } from 'rxjs';

const authPermissions = {
  canRead: true,
  canCreate: true,
  canUpdate: true,
  canDelete: true,
};

const alertsTable = new AppTable('alertsTable', 1, 10, 'description', 'asc', false, false, 'no-border');

export const externalAlertsTableColumns: AppTableColumn[] = [
  { id: 'alertName', prop: 'name', displayName: 'Name', type: 'text-with-tooltip',
    valueFormatter: (row: AlertsTableRow) => row.description },
  { id: 'emailAlert', prop: 'type', displayName: 'Email Me', type: 'toggle', actionId: EXTERNAL_ALERTS_SENTINEL_GROUP },
];

export const externalAlertsTable: AlertsTable = {
  group: EXTERNAL_ALERTS_SENTINEL_GROUP,
  title: 'External Alerts',
  hasFooter: false,
  table: alertsTable,
  tableColumns: externalAlertsTableColumns,
  authUser: authPermissions,
  isLoading: true,
  tableRows$: of([]),
};

export const publisherDealsExternalAlertsConfig: AlertsConfig = {
  alertsTable: externalAlertsTable,
};
