import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import _ from 'lodash';

import { Store } from '@ngrx/store';
import * as PublishersActions from '../_store/publishers.actions';
import * as fromPublishers from '../_store/publishers.reducer';

import { FormHelper } from '../../../../_common';
import { ApiService } from '../../../../shared/_services/api.service';
import { SharedService } from '../../../../shared/_services/shared.service';
import {
  AppFilter,
  AppTable,
  CommonDataModel, JapiQuery,
  PublisherEdit,
  ResponseFromServer, ThinPublisher
} from '../../../../shared/_models/models';
import { formGroupParamsByPaymentMethod } from './publishers.config';
import { SignupType } from '../../../signup/_services/signup.config';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { SharedStoreService } from '../../../../shared/_services/shared-store.service';
import { PUBLISHER_FORECAST_PERMISSION_NAME } from 'src/app/shared/_constants/publishers';

@Injectable({ providedIn: 'root' })
export class SupplyPublisherService {

  constructor(
    private http: HttpClient,
    private fh: FormHelper,
    private store: Store<fromPublishers.PublishersState>,
    private api: ApiService,
    private sharedService: SharedService,
    private sharedStoreService: SharedStoreService,
  ) {}

  getFinancialDocumentFile(publisherId, fileId): any {
    return this.http.get(`${environment.javaApiUrl}/publisher/${publisherId}/financialDocument/${fileId}`, { responseType: 'blob' });
  }

  createPublisher(payload): any {
    return this.api.createPublisher(payload);
  }

  registerUserJava(payload: any, signupType: SignupType): Observable<any> {
    return this.api.registerPublisher(payload, signupType);
  }

  getPublishers(query: any) {
    this.store.dispatch(new PublishersActions.FetchPublishersRequestAction());
    this.api.getPublishers(query)
      .subscribe((res: any) => {
        this.store.dispatch(new PublishersActions.FetchPublishersSuccessAction({ publishers: res }));
      },
      (err: any) => {
        this.store.dispatch(new PublishersActions.FetchPublishersFailureAction(err));
        this.sharedService.onHttpCompletionResolve(false, 'Failure', 'Publishers fetch failed',
          '', '');
      });
  }
  getFilteredPublishers(query: JapiQuery): Observable<ResponseFromServer<ThinPublisher[]>> {
    return this.api.getPublishers(query);
  }
  getOperationsOwners() {
    this.store.dispatch(new PublishersActions.FetchPublishersOpsOwnersRequestAction());
    this.sharedStoreService.getSharedAsset('opsOwners')
      .subscribe((res: any) => {
        this.store.dispatch(new PublishersActions.FetchPublishersOpsOwnersSuccessAction({ operationsOwners: res.data }));
      },
      (err: any) => {
        this.store.dispatch(new PublishersActions.FetchPublishersOpsOwnersFailureAction(err));
        this.sharedService.onHttpCompletionResolve(false, 'Failure', 'Operations owners fetch failed',
          '', '');
      });
  }

  updatePublisherStatusById(updatedPublisher: any, publishers: CommonDataModel) {
    this.api.updatePublisherStatusById(updatedPublisher.publisherId, updatedPublisher.status)
      .subscribe((res: any) => {
        const idx = _.findIndex(publishers.data, ['publisherId', updatedPublisher.publisherId]);
        publishers.data.splice(idx, 1, updatedPublisher);
        this.store.dispatch(new PublishersActions.FetchPublishersSuccessAction({ publishers: publishers }));
        this.sharedService.onHttpCompletionResolve(true, 'Success', 'Publisher status updated',
          '', '');
      },
      (err: any) => {
        this.sharedService.onHttpCompletionResolve(false, 'Failure', 'Publisher update failed',
          '', '');
      });
  }

  deletePublisher(publisherId: number, publisherFilters: AppFilter[], publishersTable: AppTable): any {
    const queryPublishers = this.sharedService.buildTableQuery(publisherFilters, publishersTable,
      ['publisherInfo', 'publisherTrafficSources']);

    this.api.deletePublisher(publisherId)
      .subscribe((res: any) => {
        // this.store.dispatch(new PublishersActions.UpdatePublisher({updatedPublisher: res}));
        this.sharedService.onHttpCompletionResolve(true, 'Success', 'Publisher was deleted',
          '', '');
        this.getPublishers(queryPublishers);
      },
      (err: any) => {
        this.sharedService.onHttpCompletionResolve(false, 'Failure', 'Publisher delete failed',
          '', '');
      });
  }

  getPublisherById(publisherId: number): Observable<PublisherEdit> {
    return this.api.getPublisherById(publisherId);
  }

  updatePublisherById(publisher: PublisherEdit): Observable<PublisherEdit> {
    return this.api.updatePublisherById(publisher);
  }

  // Transform functions

  // get from api
  transformPublisherPermissionsFromApi(currentPublisher) {
    const permissions = {};
    currentPublisher.permissions.forEach(permission => {
      switch (permission.name) {
        case 'publisher.reporting.deals':
          permissions['reportingDealPermission'] = true;
          break;
        case 'publisher.reporting.detailed':
          permissions['reportingDetailedPermission'] = true;
          break;
        case 'publisher.reporting.demand':
          permissions['reportingDemandPermission'] = true;
          break;
        case 'publisher.deal.show':
          permissions['dealsShowPermission'] = true;
          break;
        case 'publisher.floors.show':
          permissions ['floorsShowPermission'] = true;
          break;
        // case 'publisher.spearad.show':
        //   permissions ['spearadShowPermission'] = true;
        //   break;
        case PUBLISHER_FORECAST_PERMISSION_NAME:
          permissions ['dealForecastPermission'] = true;
          break;
        default:
          break;
      }
    });
    return permissions;
  }

  transformPublisherBlockListsFromApi(currentPublisher: PublisherEdit): void {
    currentPublisher.btype = currentPublisher.btype?.map(x => Number(x));
    currentPublisher.battr = currentPublisher.battr?.map(x => Number(x));
    currentPublisher.dspAccountExcludeList = currentPublisher.dspAccountExcludeList?.map(x => Number(x));
  }

  // send to api
  transformPublisherPermissionsToApi(currentPublisher): void {
    const publisherPermissions = [{ name: 'publisher.reporting.default' }];
    if (currentPublisher.reportingDealPermission) {
      publisherPermissions.push({ name: 'publisher.reporting.deals' });
    }
    if (currentPublisher.reportingDetailedPermission) {
      publisherPermissions.push({ name: 'publisher.reporting.detailed' });
    }
    if (currentPublisher.reportingDemandPermission) {
      publisherPermissions.push({ name: 'publisher.reporting.demand' });
    }
    if (currentPublisher.dealsShowPermission) {
      publisherPermissions.push({ name: 'publisher.deal.show' });
      if (currentPublisher.dealForecastPermission) {
        publisherPermissions.push({ name: PUBLISHER_FORECAST_PERMISSION_NAME });
      }
    }
    if (currentPublisher.floorsShowPermission) {
      publisherPermissions.push({ name: 'publisher.floors.show' });
    }

    // if (currentPublisher.spearadShowPermission) {
    //   publisherPermissions.push({name: 'publisher.spearad.show'});
    // }

    delete currentPublisher.floorsShowPermission;
    delete currentPublisher.reportingDealPermission;
    delete currentPublisher.reportingDemandPermission;
    delete currentPublisher.reportingDetailedPermission;
    delete currentPublisher.dealsShowPermission;
    delete currentPublisher.dealForecastPermission;
    currentPublisher.permissions = publisherPermissions;
  }

  transformPublisherInfoToApi(currentPublisherInfo): any {
    currentPublisherInfo.adServerTech = currentPublisherInfo.adServerTechId ? { id: currentPublisherInfo.adServerTechId } : null;
    currentPublisherInfo.bizdevOwner = currentPublisherInfo.bizdevOwnerId ? { id: currentPublisherInfo.bizdevOwnerId } : null;
    currentPublisherInfo.operationsOwner = currentPublisherInfo.operationsOwnerId ? { id: currentPublisherInfo.operationsOwnerId } : null;
    delete currentPublisherInfo.adServerTechId;
    delete currentPublisherInfo.bizdevOwnerId;
    delete currentPublisherInfo.operationsOwnerId;
    currentPublisherInfo.propertyNotes = (currentPublisherInfo.propertyNotes) ? currentPublisherInfo.propertyNotes.join(',') : '';
    return currentPublisherInfo;
  }

  transformPublisherBlockedListsToApi(currentPublisher): void {
    currentPublisher.bcat = (currentPublisher.bcat && currentPublisher.bcat.length !== 0) ? currentPublisher.bcat : null;
    currentPublisher.btype = (currentPublisher.btype && currentPublisher.btype.length !== 0) ? currentPublisher.btype : null;
    currentPublisher.battr = (currentPublisher.battr && currentPublisher.battr.length !== 0) ? currentPublisher.battr : null;
    currentPublisher.badv = (currentPublisher.badv && currentPublisher.badv.length !== 0) ? currentPublisher.badv : null;
    currentPublisher.tldBadv = (currentPublisher.tldBadv && currentPublisher.tldBadv.length !== 0) ? currentPublisher.tldBadv : null;
    currentPublisher.dspAccountExcludeList = (currentPublisher.dspAccountExcludeList && currentPublisher.dspAccountExcludeList.length !== 0) ? currentPublisher.dspAccountExcludeList : null;
  }
  // End api's transformations

  // Payment functions
  getFormGroupParamsByPaymentMethod(type) {
    if (!type) {
      return {};
    }
    return _.cloneDeep(formGroupParamsByPaymentMethod[type]);
  }

  getPaymentEntity(publisher, type, id) {
    if (id === null || type === null || type === 'NONE') {
      return {};
    }
    const paymentEntity = _.cloneDeep(publisher.payment[`publisherPaymentMethod${_.capitalize(type)}`]);
    return paymentEntity || {};
  }
}
