import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  SentinelResponse,
  SentinelRule,
  SentinelSubscription,
  SentinelSubscriptionResponse,
  ClusivityStatus,
  UserType,
  CtrlUserAuthorities,
} from '../../_models/models';
import { EMPTY, forkJoin, Observable, of, Subject } from 'rxjs';
import {
  catchError, filter,
  map,
  switchMap, switchMapTo,
  tap,
  takeUntil,
} from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertsService } from '../../_services/alerts/alerts.service';
import { SharedService } from '../../_services/shared.service';
import {
  AlertsConfig,
  AlertsTableRow,
  EXTERNAL_ALERTS_SENTINEL_GROUP,
  SentinelRuleGroup
} from '../../_services/alerts/types/config.types';
import { AlertWithStatus } from '../../_services/alerts/types';
import { AlertTargetDetails } from '../../_services/alerts/types/index';
import { AlertsConfigMapper } from '../../_services/alerts/configs/config.mapper';
import { AlertTargetsFactory } from '../../_services/alerts/targets/alert-targets-factory';

@Component({
  selector: 'app-alerts-management',
  templateUrl: './alerts-management.component.html',
  styleUrls: ['./alerts-management.component.less']
})
export class AlertsManagementComponent implements OnInit, OnDestroy {


  @Input()
    user: CtrlUserAuthorities;
  @Output() alertsSubmitResult: EventEmitter<{ status: boolean; message: string }> = new EventEmitter();

  userType: UserType;
  alertsToggleStatus: Map<number, AlertWithStatus> = new Map();

  alertsConfigs: AlertsConfig[] = [];
  hasAlertsConfigs = true;

  private unsubscribe$ = new Subject<void>();


  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertsService: AlertsService,
    private sharedService: SharedService,
    private alertTargetsFactory: AlertTargetsFactory
  ) {
  }
  ngOnInit(): void {
    if (this.user) {
      this.userType = this.user.type;
      this.initAlertsConfigs();
      this.initAlertsTablesData();
    }
  }

  initAlertsConfigs(): void {
    this.alertsConfigs = AlertsConfigMapper.getConfigs(this.user);
    this.hasAlertsConfigs = this.alertsConfigs.length > 0;
  }

  initSentinelSubscriptions(): Observable<SentinelResponse<SentinelSubscriptionResponse[]>> {
    return this.alertsService.getActiveSubscriptions(this.user.id)
      .pipe(
        catchError(() => this.sharedService.handleErrors('Failed to load publishers subscriptions')),
      );
  }

  initExternalUsersTable(rules: SentinelRule[]): Observable<void> {

    const observables = this.alertsConfigs.map((config: AlertsConfig) => {
      const relevantRules = rules.filter(rule => rule.groupName === config.alertsTable.group);
      return this.createAlertsTableRowsWithRules(relevantRules).pipe(
        switchMap(rows => this.initAlertsToggleStatusMap(rows)
          .pipe(
            switchMapTo(this.initSentinelSubscriptions()),
            switchMap(subscriptionsResponse => this.toggleCheckboxesBySubscriptions(rows, subscriptionsResponse.data)),
          ))
      ).subscribe(updatedRows => {
        config.alertsTable.tableRows$ = of(updatedRows);
        config.alertsTable.isLoading = false;
      });
    });

    return forkJoin(observables).pipe(
      map(() => void 0)
    );

  }

  initSentinelRules(): Observable<SentinelResponse<SentinelRule[]>> {
    return this.alertsService.getSentinelRules(this.userType);
  }


  handleAlertsToggle(): Observable<SentinelResponse<SentinelSubscriptionResponse[]>[]> {
    const selectedAlerts = Array.from(this.alertsToggleStatus.values());
    const alertsIdsToDisable: number[] = this.filterSelectedAlertsByToggle(selectedAlerts, false);
    const alertsIdsToEnable: number[] = this.filterSelectedAlertsByToggle(selectedAlerts, true);

    let alertsToDisable$: Observable<SentinelResponse<SentinelSubscriptionResponse[]>> = of({ data: [] });
    let alertsToEnable$: Observable<SentinelResponse<SentinelSubscriptionResponse[]>> = of({ data: [] });


    if ((alertsIdsToDisable.length === 0 && alertsIdsToEnable.length === 0)) {
      return of([{ data: [] }]);
    }

    if (alertsIdsToEnable.length > 0) {
      const alertsToEnableQuery: SentinelSubscription[] = alertsIdsToEnable
        .map(alert => this.alertsService.buildSentinelQuery(alert, this.user));
      alertsToEnable$ = this.alertsService.subscribeManyAlerts(alertsToEnableQuery)
        .pipe(catchError((err) => this.onFailedAlertsChange(err, 'Could not subscribe')));
    }

    if (alertsIdsToDisable.length > 0) {
      const subscriptionIdsToDisable: number[] = this.alertsService.getSubscriptionsIdsByRuleIds(alertsIdsToDisable);
      alertsToDisable$ = this.alertsService.unsubscribeManyAlerts(subscriptionIdsToDisable)
        .pipe(catchError((err) => this.onFailedAlertsChange(err, 'Could not unsubscribe')));
    }
    return this.activateAlerts(alertsToEnable$, alertsToDisable$);
  }

  onAlertRowAction(event: { row: AlertsTableRow; action: SentinelRuleGroup }): void {

    if (event.action === EXTERNAL_ALERTS_SENTINEL_GROUP) {
      this.toggleExternalAlertSubscription(event.row);
    } else {
      this.navigateToEditAlert(event.action, event.row.ruleId);
    }
  }

  ngOnDestroy(): void {
    this.alertsConfigs.forEach(config => {
      config.alertsTable.tableRows$ = of([]);
      config.alertsTable.isLoading = true;
    });
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private toggleExternalAlertSubscription(row: AlertsTableRow): void {
    const alertId: number = row.ruleId;
    const alertToUpdate: AlertWithStatus = this.alertsToggleStatus.get(alertId);
    alertToUpdate.status = this.switchStatus(alertToUpdate.status);
    this.alertsToggleStatus.set(alertId, alertToUpdate);
  }

  private navigateToEditAlert(ruleGroup: SentinelRuleGroup, ruleId: number): void {
    this.router.navigate([`alerts/${ruleId}`],
      { relativeTo: this.activatedRoute, state: { alertGroup: ruleGroup } });
  }

  private filterAlertsByStatus(alerts: AlertWithStatus[], status: ClusivityStatus): AlertWithStatus[] {
    return alerts.filter(alert => alert.status === status);
  }

  private filterSelectedAlertsByToggle(selectedAlerts: AlertWithStatus[], toggled: boolean): number[] {
    const alertsFilteredByStatus = toggled ?
      this.filterAlertsByStatus(selectedAlerts, 'ACTIVE') : this.filterAlertsByStatus(selectedAlerts, 'INACTIVE');

    return alertsFilteredByStatus
      .filter(alert => {
        const existingAlert: SentinelSubscriptionResponse = this.alertsService.activeSubscriptions$.value
          .find(sub => sub.rules.id === alert.ruleId);
        if (!existingAlert && toggled || existingAlert && !toggled) {
          return alert;
        }
      }).map(alert => alert.ruleId);
  }


  private initAlertsToggleStatusMap(rows: AlertsTableRow[]): Observable<AlertsTableRow[]> {
    rows.forEach(row => this.alertsToggleStatus.set(row.ruleId, {
      ruleId : row.ruleId,
      status: row.status
    }));
    return of(rows);
  }

  private toggleCheckboxesBySubscriptions(rows: AlertsTableRow[], subscriptions: SentinelSubscriptionResponse[]):
  Observable<AlertsTableRow[]> {

    if (subscriptions.length > 0) {
      subscriptions.forEach(subscription => this.updateRowWithSubscription(subscription, rows));
    }

    return of(rows);
  }

  private updateRowWithSubscription(subscription: SentinelSubscriptionResponse, rows: AlertsTableRow[]): void {
    const alert: AlertWithStatus = this.alertsToggleStatus.get(subscription.rules.id);
    this.alertsToggleStatus.set(alert.ruleId, { ...alert, status: 'ACTIVE' });
    const alertRowToUpdate: AlertsTableRow = rows.find(row => row.ruleId === subscription.rules.id);
    alertRowToUpdate.status = 'ACTIVE';
  }


  private onFailedAlertsChange(err: Error, message: string) {
    console.error(err);
    this.alertsSubmitResult.emit({ status: false, message: message });
    return EMPTY;
  }

  private activateAlerts(enable$: Observable<SentinelResponse<SentinelSubscriptionResponse[]>>,
    disable$: Observable<SentinelResponse<SentinelSubscriptionResponse[]>>):
    Observable<SentinelResponse<SentinelSubscriptionResponse[]>[]> {
    return forkJoin([enable$, disable$])
      .pipe(tap(([enable, disable]) => {
        if (enable.data?.length > 0) {
          this.alertsService.activeSubscriptions$.next([...this.alertsService.activeSubscriptions$.value, ...enable.data]);
        }

        if (disable.data?.length > 0) {
          const deletedIds: number[] = disable.data.map(subscription => subscription.id);
          this.alertsService.activeSubscriptions$.next( this.alertsService.activeSubscriptions$.value
            .filter(subscription => !deletedIds.includes(subscription.id)));
        }

        this.alertsSubmitResult.emit({ status: true, message: 'Updated subscriptions' });
      }));
  }

  private switchStatus (switchToggleStatus: ClusivityStatus): ClusivityStatus {
    return switchToggleStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
  }


  private createAlertsTableRowsWithRules (rules: SentinelRule[]): Observable<AlertsTableRow[]> {

    return of(rules
      .sort((ruleA, ruleB) => ruleA.name < ruleB.name ? -1 : 1)
      .map(fetchedRule => ({
        description: fetchedRule.description,
        status: 'INACTIVE',
        ruleId: fetchedRule.id,
        notificationName: fetchedRule.notificationName,
        name: fetchedRule.name
      })
      ));
  }

  private mapAlertsTargetSubscriptionsToRows(
    rows: AlertsTableRow[],
    subscriptions: SentinelSubscriptionResponse[],
    alertsTargetMaps: Map<string | number, string>,
    config: AlertsConfig
  ): AlertsTableRow [] {

    subscriptions?.forEach(subscription => {
      const alertRowToUpdate = rows
        .findIndex(alertRow => alertRow.notificationName === subscription.rules.notificationName);

      rows[alertRowToUpdate].alertTargets = subscription.templateValue.values[config.subscriptionTargetField]
        ?.map(alertTarget => {
          const alertName = alertsTargetMaps.get(alertTarget);
          return { target: alertName };
        });
    });

    return rows;
  }

  private initAlertsRowsWithMaps(
    rules: SentinelRule[],
    subscriptions: SentinelSubscriptionResponse[],
    alertsTargetMaps: Map<string | number, string>,
    config: AlertsConfig
  ): Observable<void> {

    return this.createAlertsTableRowsWithRules(rules).pipe(
      tap(rows => {
        const updatedRows = this.mapAlertsTargetSubscriptionsToRows(rows, subscriptions, alertsTargetMaps, config);
        config.alertsTable.tableRows$ = of(updatedRows);
        config.alertsTable.isLoading = false;
      }),
      map(() => void 0)
    );

  }

  private buildAlertsTargetMaps(alerts: AlertTargetDetails[]): Map<string | number, string> {
    const alertsTargetMaps: Map<string | number, string> = new Map();

    alerts?.forEach(alert => alertsTargetMaps.set(alert.targetId, alert.targetDescription ? alert.targetDescription : alert.targetName));
    return alertsTargetMaps;
  }

  private initAlertsRows(
    rules: SentinelRule[],
    subscriptions: SentinelSubscriptionResponse[],
    config: AlertsConfig
  ): Observable<void> {

    const alertTargetsHandler = this.alertTargetsFactory.createAlertTargets(config);

    return alertTargetsHandler.fetchTargetsData(subscriptions).pipe(
      switchMap(response => {
        const alertsTargetMaps = this.buildAlertsTargetMaps(response?.data);
        return this.initAlertsRowsWithMaps(rules, subscriptions, alertsTargetMaps, config);
      })
    );
  }

  private initAlertsTablesDataWithSubscriptions(
    rules: SentinelRule[],
    subscriptions: SentinelSubscriptionResponse[]
  ): Observable<void> {

    const observables = this.alertsConfigs.map((config: AlertsConfig) => {

      const relevantRules = rules.filter(rule => rule.groupName === config.alertsTable.group);
      const relevantSubscriptions = subscriptions.filter(sub =>
        relevantRules.some(rule => rule.id === sub.rules.id)
      );

      return this.initAlertsRows(relevantRules, relevantSubscriptions, config);

    });

    return forkJoin(observables).pipe(
      map(() => void 0)
    );
  }

  private initAlertsTablesDataWithRules(rules: SentinelRule[]): Observable<void> {
    return this.alertsService.getActiveSubscriptions(this.user.id).pipe(
      switchMap(subscriptionResponse =>
        this.initAlertsTablesDataWithSubscriptions(rules, subscriptionResponse.data)
      )
    );
  }

  private initAlertsTablesDataByTargetsHandlers(): Observable<void> {
    const groups = this.alertsConfigs.map((config: AlertsConfig) => config.alertsTable.group);

    return this.alertsService.getSentinelRulesByMultipleGroups(groups).pipe(
      switchMap(rulesResponse => this.userType === 'EXTERNAL' ?
        this.initExternalUsersTable(rulesResponse.data) :
        this.initAlertsTablesDataWithRules(rulesResponse.data))
    );

  }

  private initAlertsTablesData(): void {
    this.alertsService.getSentinelKongApiKey().pipe(
      filter(apiKey => apiKey.length > 0),
      switchMapTo(
        this.initAlertsTablesDataByTargetsHandlers().pipe(
          catchError(() => EMPTY)
        )),
      takeUntil(this.unsubscribe$)
    ).subscribe();
  }

}
