import { Observable } from 'rxjs';
import { AppTable, AppTableColumn, AuthPermissions, ClusivityStatus, ModalButton } from 'src/app/shared/_models/models';

export interface AlertsTableRow {
  name: string;
  description: string;
  notificationName: string;
  ruleId: number;
  status: ClusivityStatus;
  alertTargets?: { target: string }[];
}

export interface AlertTargetDetails {
  targetId: number | string;
  targetName?: string;
  targetDescription?: string;
}

export interface EditAlertRow extends AlertsTableRow {
  subscriptionId: number;
  alertTarget: AlertTargetDetails;
}

export type SentinelRuleGroup = 'deals_internal' | 'deals_external' | 'exchange_deals_internal';
export const INTERNAL_ALERTS_SENTINEL_GROUP: SentinelRuleGroup = 'deals_internal';
export const EXTERNAL_ALERTS_SENTINEL_GROUP: SentinelRuleGroup = 'deals_external';
export const EXCHANGE_DEALS_INTERNAL_ALERTS_SENTINEL_GROUP: SentinelRuleGroup = 'exchange_deals_internal';

export interface AlertRowActionEvent {
  row: EditAlertRow;
  action: string;
  group: SentinelRuleGroup;
}

export type AlertWithStatus = Pick<AlertsTableRow, 'ruleId' | 'status'>;

export interface AlertsTable {
  group: SentinelRuleGroup;
  title: string;
  hasFooter: boolean;
  table: AppTable;
  tableColumns: AppTableColumn[];
  authUser: AuthPermissions;
  isLoading: boolean;
  tableRows$: Observable<AlertsTableRow[]>;
}

export interface EditAlertsTable {
  table: AppTable;
  tableColumns: AppTableColumn[];
  authUser: AuthPermissions;
  isLoading: boolean;
  alertsTableData: EditAlertRow[];
}

export interface AlertsConfig {
  alertsTable: AlertsTable;
  editAlertsTable?: EditAlertsTable;
  addAlertModalButton?: ModalButton[];
  alertsTargetsFields?: AlertTargetDetails;
  subscriptionTargetField?: string;
  addAlertTargetButtonText?: string;
}
