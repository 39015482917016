import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AlertTargetDetails } from '../types/config.types';
import { JapiQuery, ResponseFromServer, SentinelSubscriptionResponse, ThinPublisher } from 'src/app/shared/_models/models';
import { cloneDeep } from 'lodash';
import { SupplyPublisherService } from '@/app/features/supply/publishers/_services/publisher.service';
import { IAlertTargets } from './ialert-targets';

@Injectable()
export class DealsInternalAlertTargets implements IAlertTargets {
  private readonly DEFAULT_API_QUERY: JapiQuery = {
    filter: {
      filters: [
        { fieldName: 'status', operation: 'EQUALS', value: 'ACTIVE' },
      ],
      withTrashed: false
    },
    paging: {
      number: 1,
      size: 30
    },
    sorting: {
      sortDirection: 'ASC',
      sortingField: 'publisherName'
    }
  };

  constructor(
    private readonly publishersService: SupplyPublisherService,
  ) {}

  fetchTargetsData(subscriptions: SentinelSubscriptionResponse): Observable<ResponseFromServer<AlertTargetDetails[]>> {
    const query = subscriptions ?
      this.buildTargetsQuery(subscriptions) :
      this.DEFAULT_API_QUERY;

    return this.publishersService
      .getFilteredPublishers(query)
      .pipe(
        map((response: ResponseFromServer<ThinPublisher[]>) => ({
          data: this.thinPublishersToAlertTargetDetails(response)
        }))
      );
  }

  searchTargets(searchValue?: string): Observable<ResponseFromServer<AlertTargetDetails[]>> {
    const query = this.generateQueryWithSearchValue(searchValue);

    return this.publishersService
      .getFilteredPublishers(query)
      .pipe(
        map((response: ResponseFromServer<ThinPublisher[]>) => ({
          data: this.thinPublishersToAlertTargetDetails(response)
        }))
      );
  }

  private buildTargetsQuery(subscriptions: SentinelSubscriptionResponse | SentinelSubscriptionResponse[]): JapiQuery {
    let publishersIds: (string | number)[];
    if (Array.isArray(subscriptions)) {
      publishersIds = subscriptions.flatMap(sub => sub.templateValue.values?.publishers);
    } else {
      publishersIds = subscriptions.templateValue.values?.publishers;
    }
    return {
      filter: {
        filters: [
          { fieldName: 'publisherId', operation: 'IN', value: publishersIds },
        ],
        withTrashed: false
      },
      paging: {
        number: 1,
        size: publishersIds?.length || 0
      },
    };
  }

  private thinPublishersToAlertTargetDetails(publishers: ResponseFromServer<ThinPublisher[]>): AlertTargetDetails[] {
    return publishers?.data?.map((publisher: ThinPublisher) => ({
      targetId: publisher.publisherId,
      targetName: publisher.publisherName,
    })) || [];
  }

  private generateQueryWithSearchValue(searchValue: string): JapiQuery {
    const query = cloneDeep(this.DEFAULT_API_QUERY);
    if (searchValue) {
      const isString = isNaN(+searchValue);
      query.filter.filters.push({
        fieldName: isString ? 'publisherName' : 'publisherId',
        operation: isString ? 'LIKE' : 'EQUALS',
        value: searchValue
      });
    }
    return query;
  }

}
