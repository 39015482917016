import { FormControl } from '@angular/forms';

export * from './config.types';
export * from './deals-internal.types';

export enum AddTargetToAlertError {
  TARGET_ALREADY_IN_TABLE = 'targetAlreadyInTable',
  TARGET_IS_REQUIRED = 'required'
}

export enum AlertRowAction {
  ALERT_TOGGLE_ACTION = 'alertToggleAction',
  ALERT_DELETE_ACTION = 'alertDeleteAction'
}

export interface AddAlertTargetForm {
  targetId: FormControl<string | number | null>;
}
