import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  CtrlUser,
  ModalActionType,
  SentinelResponse,
  SentinelRule,
  SentinelSubscription,
  SentinelSubscriptionResponse,
} from '../../../_models/models';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { AlertsService } from '../../../_services/alerts/alerts.service';
import { catchError, filter, finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, Observable, Subject } from 'rxjs';
import { AuthenticationService } from '../../../../auth/_services/authentication.service';
import { FormHelper } from '../../../_common/form.helper';
import { SharedService } from 'src/app/shared/_services/shared.service';
import { AddAlertTargetForm, AddTargetToAlertError, AlertRowAction, SentinelRuleGroup } from 'src/app/shared/_services/alerts/types';

import { AlertsConfig, AlertTargetDetails, EditAlertRow } from 'src/app/shared/_services/alerts/types/config.types';
import { AddTargetToAlertComponent } from './add-target-to-alert/add-target-to-alert.component';
import { IAlertTargets } from '@/app/shared/_services/alerts/targets';
import { AlertsConfigMapper } from '@/app/shared/_services/alerts/configs/config.mapper';
import { AlertTargetsFactory } from '@/app/shared/_services/alerts/targets/alert-targets-factory';

@Component({
  selector: 'app-edit-alert',
  templateUrl: './edit-alert.component.html',
  styleUrls: ['./edit-alert.component.less']
})
export class EditAlertComponent implements OnInit, OnDestroy {

  @ViewChild(AddTargetToAlertComponent) addAlertTarget: AddTargetToAlertComponent;

  currentAlert: Pick<SentinelRule, 'id' | 'name' >;

  isModalVisible = false;
  successfulModalSubmit = false;
  user: CtrlUser = null;
  userRuleGroup: SentinelRuleGroup;
  currentAlertActiveSubscription: SentinelSubscriptionResponse = null;

  addAlertTargetForm: FormGroup<AddAlertTargetForm>;

  alertTargetsHandler: IAlertTargets;
  alertConfig: AlertsConfig;

  private unsubscribe$ = new Subject<void>();


  constructor(
    private readonly fb: FormBuilder,
    private readonly alertsService: AlertsService,
    private readonly alertTargetsFactory: AlertTargetsFactory,
    private readonly route: ActivatedRoute,
    private readonly auth: AuthenticationService,
    private readonly fh: FormHelper,
    private readonly sharedService: SharedService,
    private readonly router: Router
  ) { }



  ngOnInit(): void {
    this.currentAlert = { name: '', id: 0 };
    this.initUserRuleGroup();
    this.initAlertConfig();
    this.initAlertTargetHandler();
    this.initAddTargetForm();
    this.alertsService.getSentinelKongApiKey()
      .pipe(
        filter(apiKeyResponse => apiKeyResponse.length > 0),
        switchMap(() => this.route.params.pipe(
          map(params => +params.id)
        )),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(alertIdParam => {
        this.initCurrentUser();
        this.initCurrentAlert(alertIdParam);
      });
  }

  ngOnDestroy(): void {
    this.alertConfig.editAlertsTable.isLoading = true;
    this.alertConfig.editAlertsTable.alertsTableData = [];
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initUserRuleGroup(): void {
    const state = history.state || this.router.getCurrentNavigation()?.extras?.state;
    if (state?.['alertGroup']) {
      this.userRuleGroup = state['alertGroup'];
    } else {
      this.sharedService.handleErrors('Alert group not found');
      this.router.navigate(['../..'], { relativeTo: this.route });
    }
  }

  initAlertConfig(): void {
    this.alertConfig = AlertsConfigMapper.getConfigByRuleGroup(this.userRuleGroup);
  }

  initAlertTargetHandler(): void {
    this.alertTargetsHandler = this.alertTargetsFactory.createAlertTargets(this.alertConfig);
  }

  initCurrentAlert(alertId: number): void {
    this.alertConfig.editAlertsTable.isLoading = true;
    this.alertsService.activeSubscriptions$
      .pipe(
        map(subscriptions => subscriptions.filter(subscription => subscription.rules.id === this.currentAlert.id)[0]),
        takeUntil(this.unsubscribe$)
      ).subscribe(res => {
        this.currentAlertActiveSubscription = res;
      });
    this.alertsService.getSentinelRuleById(alertId)
      .pipe(
        catchError(() => {
          this.sharedService.handleErrors('Could not load alert');
          this.alertConfig.editAlertsTable.isLoading = false;
          return EMPTY;
        }),
        takeUntil(this.unsubscribe$),
      ).subscribe((res) => {
        if (!res.data || res.data.groupName !== this.userRuleGroup) {
          this.sharedService.handleErrors('Alert was not found');
          this.alertConfig.editAlertsTable.isLoading = false;
          return;
        }
        this.currentAlert.id = res.data.id;
        this.currentAlert.name = res.data.name;
        this.initActiveSubscriptions();
      });
  }


  initCurrentUser(): void {
    this.user = this.auth.currentUserValue;
  }

  initAddTargetForm(): void {
    this.addAlertTargetForm = this.fb.group({
      targetId: this.fb.control(null, [
        Validators.required,
        this.targetExistsValidator()
      ]),
    });
  }

  initActiveSubscriptions(): void {
    if (!this.currentAlertActiveSubscription) {
      this.getActiveSubscription()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(res => this.alertConfig.editAlertsTable.alertsTableData = res);
    } else {
      this.alertTargetsHandler.fetchTargetsData(this.currentAlertActiveSubscription)
        .pipe(
          map(res => this.mapSubscriptionsToRows(this.currentAlertActiveSubscription, res.data)),
          finalize(() => {
            this.alertConfig.editAlertsTable.isLoading = false;
          }),
          takeUntil(this.unsubscribe$)
        )
        .subscribe(res => {
          this.alertConfig.editAlertsTable.alertsTableData = res;
        });
    }
  }

  getActiveSubscription(): Observable<EditAlertRow[]> {
    return this.alertsService.getActiveSubscriptions(this.user.id)
      .pipe(
        map(subscriptions => subscriptions.data.filter(s => s.rules.id === this.currentAlert.id)),
        filter(subscriptions => subscriptions?.length > 0),
        map(subscriptions => subscriptions[0]),
        tap( res => this.currentAlertActiveSubscription = res),
        switchMap((subscriptions: SentinelSubscriptionResponse) =>
          this.alertTargetsHandler.fetchTargetsData(subscriptions)
            .pipe(
              map(res => this.mapSubscriptionsToRows(subscriptions, res.data)),
              catchError(() => this.sharedService.handleErrors(`Failed to load ${this.alertConfig.addAlertTargetButtonText}`)))
        ),
        finalize(() => this.alertConfig.editAlertsTable.isLoading = false),
        catchError(() => this.sharedService.handleErrors('Failed to load active subscriptions'))
      );
  }

  onModalEvent(modalButtonClickEvent: ModalActionType): void {

    this.fh.markFormGroupDirtyAndUpdateValidity(this.addAlertTargetForm);
    switch (modalButtonClickEvent) {
      case 'APPROVE':
        this.addAlertTarget.onSubmit();
        if (!this.successfulModalSubmit) {
          return;
        }
        this.addAlertToTable();
        break;
      case 'CANCEL':
        break;
    }
    this.addAlertTargetForm.controls.targetId.reset();
    this.toggleModalView();
  }

  toggleModalView(): void {
    this.isModalVisible = !this.isModalVisible;
  }

  informModalSubmit(submitValue: boolean): void {
    if (!submitValue) {
      const targetId = this.addAlertTargetForm.controls.targetId;
      if (targetId.errors?.[AddTargetToAlertError.TARGET_ALREADY_IN_TABLE]) {
        this.sharedService.showNotification(
          'error',
          `Already subscribed to ${this.alertConfig.addAlertTargetButtonText}`);
      } else if (targetId.errors?.[AddTargetToAlertError.TARGET_IS_REQUIRED]) {
        this.sharedService.showNotification(
          'error',
          `You must select ${this.alertConfig.addAlertTargetButtonText}`
        );
      } else {
        this.sharedService.showNotification(
          'error',
          'Failed to add subscription'
        );
      }
    }
    this.successfulModalSubmit = submitValue;
  }

  onAlertRowAction(rowEvent: { row: EditAlertRow; action: AlertRowAction }): void {
    switch (rowEvent.action) {
      case AlertRowAction.ALERT_TOGGLE_ACTION:
        this.toggleAlert(rowEvent.row);
        return;
      case AlertRowAction.ALERT_DELETE_ACTION:
        this.deleteAlert(rowEvent.row)
          .subscribe(res => this.handleDeleteAlert(res, rowEvent));
        return;
    }

  }

  activateAlert(alertTarget: AlertTargetDetails): void {
    this.getActivateAlertObservable(alertTarget.targetId)
      .pipe(catchError(() => this.sharedService.handleErrors('Failed to subscribe')))
      .subscribe(res => this.addNewSubscriptiontoTable(res.data, alertTarget));
  }

  toggleAlert(alert: EditAlertRow): void {
    if (alert.status === 'ACTIVE') {
      this.deleteAlert(alert).subscribe(() => this.deactivateAlert(alert));
    } else {
      this.activateAlert(alert.alertTarget);
    }
  }


  deleteAlert(alert: EditAlertRow): Observable<SentinelSubscriptionResponse[]> {

    const filteredAlertTargetsArray = this.currentAlertActiveSubscription
      ?.templateValue
      .values[this.alertConfig.subscriptionTargetField]
      .filter(alertTarget => alertTarget !== alert.alertTarget.targetId);

    if (filteredAlertTargetsArray?.length > 0) {
      const query: SentinelSubscription = this.alertsService
        .buildSentinelQuery(alert.ruleId, this.user);
      query.templateValue.values[this.alertConfig.subscriptionTargetField] = filteredAlertTargetsArray;
      return this.updateSubscriptionAfterDelete(query)
        .pipe(
          map(res => this.updateActiveSubscriptions(res))
        );
    } else {
      return this.alertsService.unsubscribeAlert(alert.subscriptionId)
        .pipe(
          catchError(() => this.sharedService.handleErrors('Failed to unsubscribe')),
          map(() => this.removeFromActiveSubscriptions(alert))
        );
    }
  }

  updateSubscriptionAfterDelete(query: SentinelSubscription) {
    return this.alertsService.updateSubscription(this.currentAlertActiveSubscription.id, query)
      .pipe(
        catchError(() => this.sharedService.handleErrors('Failed to unsubscribe')),
        map(subscriptionResponse => subscriptionResponse.data)
      );
  }

  addAlertToTable(): void {
    this.activateAlert(this.addAlertTarget.selectedTarget);
  }

  targetExistsValidator(): ValidatorFn {
    return (control: AbstractControl<string | number | null>): ValidationErrors | null => {
      const targetAlreadyInTable = this.alertConfig.editAlertsTable.alertsTableData
        ?.find(activeSubscription => activeSubscription.alertTarget.targetId === control.value);
      return targetAlreadyInTable ? { targetAlreadyInTable: { value: control.value } } : null;
    };
  }

  removeFromActiveSubscriptions(alert: EditAlertRow): SentinelSubscriptionResponse[] {
    return this.alertsService.activeSubscriptions$.value
      .filter(sub => sub.id !== alert.subscriptionId);
  }


  getActivateAlertObservable(targetId: string | number): Observable<SentinelResponse<SentinelSubscriptionResponse>> {
    const sentinelQuery: SentinelSubscription = this.alertsService.buildSentinelQuery(this.currentAlert.id, this.user);

    const targetsArray: (string | number)[] = this.getFilteredTargetssArray(targetId);

    sentinelQuery.templateValue.values[this.alertConfig.subscriptionTargetField] = targetsArray;

    if (targetsArray.length === 1) {
      return this.alertsService.subscribeAlert(sentinelQuery);
    }

    return this.alertsService.updateSubscription(this.currentAlertActiveSubscription.id, sentinelQuery);
  }

  getFilteredTargetssArray(targetId: string | number): (string | number)[] {
    const currentSubscriptionTargets: (string | number) [] =
      this.currentAlertActiveSubscription?.templateValue.values[this.alertConfig.subscriptionTargetField] || [];

    currentSubscriptionTargets.push(targetId);

    return [...new Set(currentSubscriptionTargets)];
  }

  updateActiveSubscriptions(subscriptionResponse: SentinelSubscriptionResponse): SentinelSubscriptionResponse[] {
    let subscriptionToUpdate: SentinelSubscriptionResponse[] = this.alertsService.activeSubscriptions$.value;

    const foundSubscriptionIndex: number = subscriptionToUpdate.findIndex(sub => sub.id === subscriptionResponse.id);

    if (foundSubscriptionIndex === -1) {
      subscriptionToUpdate = [subscriptionResponse];
    } else {
      subscriptionToUpdate[foundSubscriptionIndex].templateValue.values[this.alertConfig.subscriptionTargetField] =
        subscriptionResponse.templateValue.values[this.alertConfig.subscriptionTargetField];
    }
    return subscriptionToUpdate;
  }

  createEditRowEntry(subscription: SentinelSubscriptionResponse, alertTarget: AlertTargetDetails): EditAlertRow {
    return {
      status: 'ACTIVE',
      ruleId: subscription.rules.id,
      alertTarget,
      name: subscription.rules.name,
      description: subscription.rules.description,
      notificationName: subscription.rules.notificationName,
      subscriptionId: subscription.id
    };
  }

  private handleDeleteAlert(
    subscriptions: SentinelSubscriptionResponse[],
    rowEvent: { row: EditAlertRow; action: AlertRowAction }
  ): void {
    this.alertsService.activeSubscriptions$.next(subscriptions);
    this.alertConfig.editAlertsTable.alertsTableData = this.alertConfig.editAlertsTable.alertsTableData.filter(
      alert => alert?.alertTarget?.targetId !== rowEvent.row?.alertTarget.targetId
    );
  }

  private deactivateAlert(alert: EditAlertRow): void {
    const alertToUpdateIndex = this.alertConfig.editAlertsTable.alertsTableData.findIndex(
      row => row.alertTarget.targetId === alert.alertTarget.targetId
    );
    this.alertConfig.editAlertsTable.alertsTableData[alertToUpdateIndex].status = 'INACTIVE';
  }

  private mapSubscriptionsToRows(subscription: SentinelSubscriptionResponse, alertTargets: AlertTargetDetails[]): EditAlertRow[] {
    return alertTargets
      .map((alertTarget: AlertTargetDetails) => this.createEditRowEntry(subscription, alertTarget));
  }

  private addNewSubscriptiontoTable(subscription: SentinelSubscriptionResponse, alertTarget: AlertTargetDetails): void {
    const newTableEntry: EditAlertRow = this.createEditRowEntry(subscription, alertTarget);
    const updatedSubscriptions = this.updateActiveSubscriptions(subscription);
    this.alertsService.activeSubscriptions$.next(updatedSubscriptions);
    this.alertConfig.editAlertsTable.alertsTableData = [...this.alertConfig.editAlertsTable.alertsTableData, newTableEntry];
  }

}
