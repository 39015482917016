import { Injectable } from '@angular/core';
import { ApiService } from './../api.service';
import {
  CtrlUser,
  OptionsLists,
  SentinelResponse,
  SentinelRule,
  SentinelSubscription,
  SentinelSubscriptionResponse
} from 'src/app/shared/_models/models';
import { switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { SharedStoreService } from './../shared-store.service';
import {
  SentinelRuleGroup,
  INTERNAL_ALERTS_SENTINEL_GROUP,
  EXTERNAL_ALERTS_SENTINEL_GROUP
} from './types';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  fetchedAlerts: SentinelRule[] = [];
  activeSubscriptions$: BehaviorSubject<SentinelSubscriptionResponse[]> = new BehaviorSubject<SentinelSubscriptionResponse[]>([]);
  sentinelKongApiKey$: Observable<string>;
  private sentinelKongApiKeySource: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(
    private readonly api: ApiService,
    private readonly sharedStoreService: SharedStoreService,
  ) {
    this.sentinelKongApiKey$ = this.sentinelKongApiKeySource.asObservable();
  }

  getSentinelKongApiKey(): Observable<string> {
    if (this.sentinelKongApiKeySource.value) {
      return this.sentinelKongApiKey$;
    }
    return this.sharedStoreService.getSharedAsset('optionsLists').pipe(
      switchMap((res: OptionsLists) => {
        this.sentinelKongApiKeySource.next(res.SentinelKongApiKey.API_KEY);
        return this.sentinelKongApiKey$;
      }),
    );
  }

  getSubscriptionsIdsByRuleIds(ruleIds: number[]): number[] {
    return this.activeSubscriptions$.value.filter(activeSub => ruleIds.includes(activeSub.rules.id))
      .map(subscription => subscription.id);
  }

  getSentinelRules(userType: string): Observable<SentinelResponse<SentinelRule[]>> {
    const alertsGroup = userType === 'EXTERNAL' ? EXTERNAL_ALERTS_SENTINEL_GROUP : INTERNAL_ALERTS_SENTINEL_GROUP;
    return this.api.getSentinelRulesByUserType(alertsGroup)
      .pipe(tap (res => this.fetchedAlerts = res.data));
  }

  getSentinelRuleById(ruleId: number): Observable<SentinelResponse<SentinelRule>> {
    return this.api.getSentinelRuleById(ruleId);
  }

  getSentinelRulesByMultipleGroups(groups: SentinelRuleGroup[]): Observable<SentinelResponse<SentinelRule[]>> {
    return this.api.getSentinelRulesByMultipleGroups(groups);
  }

  getActiveSubscriptions(userId: number): Observable<SentinelResponse<SentinelSubscriptionResponse[]>> {
    return this.api.getSentinelSubscriptions(userId).pipe(tap(res => {
      this.activeSubscriptions$.next(res.data);
    }));
  }

  subscribeAlert(query: SentinelSubscription): Observable<SentinelResponse<SentinelSubscriptionResponse>> {
    return this.api.subscribeAlert(query);
  }

  unsubscribeAlert(subscriptionId: number): Observable<SentinelResponse<SentinelSubscriptionResponse>> {
    return this.api.unsubscribeAlert(subscriptionId);
  }

  updateSubscription(subscriptionId: number, query: SentinelSubscription): Observable<SentinelResponse<SentinelSubscriptionResponse>> {
    return this.api.updateSubscription(subscriptionId, query);
  }
  subscribeManyAlerts(query: SentinelSubscription[]): Observable<SentinelResponse<SentinelSubscriptionResponse[]>> {
    return this.api.subscribeManyAlerts(query);
  }

  unsubscribeManyAlerts(subscriptionsIds: number[]): Observable<SentinelResponse<SentinelSubscriptionResponse[]>> {
    return this.api.unsubscribeManyAlerts(subscriptionsIds);
  }

  buildSentinelQuery(alertId: number, user: CtrlUser): SentinelSubscription {
    const query: SentinelSubscription = {
      templateValue: {
        values: {
          email: user.email,
          days: 7,
        },
        handler: {}
      },
      ruleId: alertId,
      userId: user.id.toString()
    };
    return query;
  }
}
