import packageJson from '../../package.json';

export const environment = {
  appVersion: packageJson.version + '-qa',
  production: false,
  googleTagContainerId: 'GTM-NHDBCX7',
  envName: 'qa',
  ondemandUser: 'NO-USER',
  javaApiUrl: 'https://ctrl-fusionapi-qa.octo-haproxy.service.iad1.apps.nxxn.io/api',
  javaApiViaKongUrl: 'https://ctrl-ssp-api-dev.octo-haproxy.service.iad1.apps.nxxn.io/api',
  javaInsightsUrl: 'https://ctrl-fusioninsights-qa.octo-haproxy.service.iad1.apps.nxxn.io/insights',
  spearadUrl: 'https://console.staging.spearad.video/login',
  spearadIframeUrl: 'https://console.staging.spearad.video',
  exchangeDealsUrl: 'https://ctrl-rx-deals-qa.octo-haproxy.service.iad1.apps.nxxn.io/api',
  sentinelApi: 'https://ctrl-ssp-api-dev.octo-haproxy.service.iad1.apps.nxxn.io/dev/sentinel',
  captcha: false,
  pendo: false,
  datadog: false,
  authServer: 'https://ctrl-ssp-api-dev.octo-haproxy.service.iad1.apps.nxxn.io/realms/fusion-rel/protocol/openid-connect',
  zendeskUrl: 'https://unrulyssp-help.zendesk.com'
};
