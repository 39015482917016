import { AppTable, AppTableColumn, ModalButton } from 'src/app/shared/_models/models';
import {
  AlertsConfig,
  AlertsTable,
  AlertsTableRow,
  AlertTargetDetails,
  EditAlertsTable,
  EXCHANGE_DEALS_INTERNAL_ALERTS_SENTINEL_GROUP
} from '../types/config.types';
import { of } from 'rxjs';

const alertsTable = new AppTable('exchangeDealsInternalAlertsTable', 1, 10, 'description', 'asc', false, false, 'no-border');

export const exchangeDealInternalAlertsTableColumns: AppTableColumn[] = [
  {
    id: 'notificationType',
    prop: 'name',
    displayName: 'Notification Type',
    type: 'text',
    valueFormatter: (row: AlertsTableRow) => row.description,
    colWidth: 'max-content'
  },
  {
    id: 'dealDescription',
    prop: 'alertTargets',
    displayName: 'Deal Name/Description',
    type: 'moreTag',
    actionId: 'alertToggleAction',
    moreTag: {
      displayNameProp: 'target',
      popoverTitle: 'More exchnage deals',
      noDataMessage: 'No exchange deals selected'
    },
    colWidth: 'max-content'
  },
  {
    id: 'editAlert', prop: 'actions', displayName: '', type: 'actions', actionId: EXCHANGE_DEALS_INTERNAL_ALERTS_SENTINEL_GROUP,
    actions: [
      { id: EXCHANGE_DEALS_INTERNAL_ALERTS_SENTINEL_GROUP, name: 'edit', auth: 'canUpdate', displayName: 'Edit' }
    ],
    colWidth: 'max-content'
  }
];

const authPermissions = {
  canRead: true,
  canCreate: true,
  canUpdate: true,
  canDelete: true,
};

export const exchangeDealInternalAlertsTable: AlertsTable = {
  group: EXCHANGE_DEALS_INTERNAL_ALERTS_SENTINEL_GROUP,
  title: 'Exchange Deal Alerts',
  hasFooter: false,
  table: alertsTable,
  tableColumns: exchangeDealInternalAlertsTableColumns,
  authUser: authPermissions,
  isLoading: true,
  tableRows$: of([]),
};

export const editExchangeDealAlertsTableColumns: AppTableColumn[] = [
  { id: 'dealId', prop: 'alertTarget.targetId', displayName: 'ID', type: 'text' },
  {
    id: 'dealName',
    prop: 'alertTarget',
    displayName: 'Deal Name/Description',
    type: 'custom-function',
    valueFormatter: (alertTarget: AlertTargetDetails) =>
      alertTarget.targetDescription ? alertTarget.targetDescription : alertTarget.targetName
  },
  {
    id: 'alertActions', prop: 'actions', displayName: '', type: 'actions',
    actions: [
      {
        id: 'alertDeleteAction',
        name: 'delete',
        auth: 'canDelete',
        displayName: 'Delete',
        desc: 'Are you sure you wish to delete this deal\'s alert?'
      }
    ]
  }
];

export const editExchangeDealAlertsTable: EditAlertsTable = {
  table: alertsTable,
  tableColumns: editExchangeDealAlertsTableColumns,
  authUser: authPermissions,
  isLoading: true,
  alertsTableData: [],
};

export const addExchangeDealsAlertModalButtons: ModalButton[] = [
  { type: 'CANCEL', text: 'Cancel' },
  { type: 'APPROVE', text: 'Save' }
];

export const exchangeDealInternalAlertsTargetsFields: AlertTargetDetails = {
  targetId: 'dealId',
  targetName: 'name',
};

export const exchangeDealsInternalAlertsConfig: AlertsConfig = {
  alertsTable: exchangeDealInternalAlertsTable,
  editAlertsTable: editExchangeDealAlertsTable,
  addAlertModalButton: addExchangeDealsAlertModalButtons,
  alertsTargetsFields: exchangeDealInternalAlertsTargetsFields,
  subscriptionTargetField: 'exchangeDeals',
  addAlertTargetButtonText: 'exchange deal'
};
