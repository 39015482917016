import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AlertsService } from '../_services/alerts/alerts.service';
import { first, switchMap } from 'rxjs/operators';

@Injectable()
export class SentinelApiInterceptor implements HttpInterceptor {
  constructor(
    private alertsService: AlertsService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.url.startsWith(environment.sentinelApi)) {
      return this.alertsService.getSentinelKongApiKey().pipe(
        first(),
        switchMap((apiKey) => {
          if (apiKey) {
            request = request.clone(
              {
                setHeaders: {
                  'apiKey': apiKey,
                }
              }
            );
          }
          return next.handle(request);
        })
      );
    }

    return next.handle(request);
  }

}
