<form [formGroup]="addAlertTargetForm" (ngSubmit)="onSubmit()">
    <div class="add-target-container">
      <nz-select
        class="target-select"
        [formControlName]="'targetId'"
        [nzPlaceHolder]="'Search a ' + alertConfig.addAlertTargetButtonText"
        nzShowSearch
        nzServerSearch
        (nzOnSearch)="onSearch($event)"
        (ngModelChange)="onAlertTargetSelect($event)"
        [nzLoading]="isLoading"
      >
        <nz-option
          *ngFor="let target of alertTargets"
          [nzValue]="target.targetId"
          [nzLabel]="getOptionLabel(target) + ' (id: ' + target.targetId + ')'"
        ></nz-option>
      </nz-select>
    </div>
  </form> 